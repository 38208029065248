import React, { useState, useEffect, useRef } from "react"
import { Link, navigate } from "gatsby"
import { firebase } from "../utils/firebase"
import { API_URL, setValToLS, isFieldError } from "../utils"
import logo from "../img/pbcba-logo.png"
import Msg from "../components/Msg"
import Submit from "../components/Submit"
import S from "./styled"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

const Signin = () => {
  const [working, setWorking] = useState(true)
  const [names, setNames] = useState({})
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})

  const formElement = useRef(null)

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setValToLS("clepbcbaAdmin", true, true)
        navigate("/admin")
      } else {
        setWorking(false)
      }
    })
  }, [])

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value ? value : "" }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/signin`)
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(names),
        headers: {
          "Content-Type": "application/json",
        },
      })
      const json = await response.json()
      handleResponse(json, null)
    } catch (error) {
      handleResponse(null, error)
    }
  }

  const handleResponse = (json, error) => {
    if (json) {
      if (json.resp === 1) {
        firebase
          .auth()
          .signInWithCustomToken(json.token)
          .then(userCredential => {
            setValToLS("clepbcbaAdmin", true, true)
            navigate("/admin")
          })
          .catch(error => {
            console.log(`${error.code} ${error.message}`)
          })
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } else {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <S.SectionContainer>
      {working ? (
        <div style={{ color: "var(--working)" }}>
          Checking authentication...
        </div>
      ) : (
        <form
          method="post"
          action="/"
          onSubmit={e => handleSubmit(e)}
          ref={formElement}
        >
          <S.Logo>
            <Link to="/">
              <img src={logo} alt="PBCBA Logo" />
            </Link>
          </S.Logo>

          <S.Inputs>
            <input
              type="text"
              name="uname"
              id="uname"
              onChange={e => handleUpdate("uname", e.target.value)}
              placeholder="Username"
              autoComplete="off"
              className={isFieldError(errors, "uname") ? `error` : ``}
            />
            <input
              type="password"
              name="pwd"
              id="pwd"
              onChange={e => handleUpdate("pwd", e.target.value)}
              placeholder="Password"
              autoComplete="off"
              className={isFieldError(errors, "pwd") ? `error` : ``}
            />
          </S.Inputs>

          <S.MsgSubmit>
            {msg.type && <Msg data={msg} />}
            {msg.type !== "working" && (
              <Submit name="Sign In" icon={faChevronCircleRight} />
            )}
          </S.MsgSubmit>

          <p>
            You will remained signed in on this device (unless you sign out).
          </p>
        </form>
      )}
    </S.SectionContainer>
  )
}

export default Signin
