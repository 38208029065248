import styled from "styled-components"

let S = {}
S.SectionContainer = styled.section`
  background-color: var(--bg);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding-bottom: 2px;

  & > form {
    width: 55%;
    border: 2px solid var(--border);
    border-radius: 4px;
    padding: 25px 35px;
    background-color: #fff;

    @media only screen and (max-width: 767px) {
      width: 75%;
    }

    @media only screen and (max-width: 649px) {
      width: 100%;
      border-left: none;
      border-right: none;
      border-radius: 0;
    }

    & > p {
      margin-top: 25px;
      padding-bottom: 0;
      font-size: 0.9rem;
      text-align: center;
    }
  }

  & > form > div {
    margin-bottom: 15px;
  }

  & > form > div img {
    width: 100px;
  }
`

S.Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

S.Inputs = styled.div`
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100px;

  & > input[type="text"],
  & > input[type="password"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    vertical-align: middle;
    padding: 7.5px 7.5px;
    font-family: inherit;
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
    outline: none;
    border: none;
    border: 2px solid var(--border);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;

    &:focus {
      border-color: var(--blue);
      z-index: 10;
    }

    &.error {
      border-color: var(--error);
      z-index: 10;
    }

    ::placeholder {
      color: var(--working);
      font-size: 0.9rem;
    }
  }

  & > input:last-child {
    top: 42px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  & > input[type="text"]:focus,
  & > input[type="text"].error {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  & > input[type="password"]:focus,
  & > input[type="password"].error {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`

S.MsgSubmit = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
`

export default S
