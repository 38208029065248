import React, { Fragment } from "react"
import Helmet from "../components/Helmet"
import Home from "../pagesSignin"

const SigninPage = () => (
  <Fragment>
    <Helmet title="Admin Sign In" descr="" admin={true} />
    <Home />
  </Fragment>
)
export default SigninPage
